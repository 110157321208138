<template>
  <div class="home">
    <div class="home-top-section">
      <HomeBanners />
      <BuyCrypto />
    </div>
    <MainCasino />
    <MainSport />
    <LastBets :tabs="tabs" header-size="2xl" :with-header-icon="false" />
    <About />
  </div>
</template>

<script setup lang="ts">
import type { Tab } from '@st/ui/components/StTabs/types'

const { t } = useI18n()

/**
 * @see useGameRecursiveMessage.ts
 */
onMounted(() => {
  window?.parent?.postMessage({ type: 'openedMainPage' }, '*')
})

useHead({
  title: t('pages.main'),
})

const tabs: Tab[] = [
  {
    id: 'casino',
    label: t('tapBar.casino'),
    icon: 'cherry-solid',
  },
  {
    id: 'sport',
    label: t('tapBar.sport'),
    icon: 'all-sports',
  },
  {
    id: 'big-bets-casino',
    label: t('lastBets.bigBets'),
    icon: 'coin-stack-1-solid',
  },
]
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-600);
  padding-bottom: var(--spacing-600);
}

.home-top-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-350);
}

.main-games {
  min-height: 256px;
}
</style>
