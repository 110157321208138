<template>
  <section class="main-casino" data-t="main-casino">
    <NuxtI18nLink to="/casino" class="title-wrapper">
      <h2 class="title" data-t="link-wqf5-casino">{{ t('casino.casino') }}</h2>
      <StIcon name="chevron-right" size="24" class="chevron" />
    </NuxtI18nLink>
    <div class="navigation">
      <StMobileTabs
        v-if="activeTab"
        v-model="activeTab"
        :tab-items="tabs"
        type="row"
        size="m"
        class="tabs"
      />
      <StButton class="all-button" size="m" to="/casino" type="gray">
        <span class="button-text">{{ t('casino.allCasino') }}</span>
        <span class="events-count" data-t="events-count">
          {{ allGamesCounter }}
        </span>
      </StButton>
    </div>
    <GamesSlider
      :games="games"
      with-show-all-button-in-slider
      :with-header="false"
      :link="showMoreLink"
      :games-total-count="showMoreCounter"
    />
    <ProvidersSlider :with-header-link="false" class="providers" />
  </section>
</template>

<script setup lang="ts">
import { useMainCasino } from './useMainCasino'

const {
  allGamesCounter,
  tabs,
  activeTab,
  games,
  showMoreCounter,
  showMoreLink,
} = useMainCasino()

const { t } = useI18n()
</script>

<style scoped>
.title-wrapper {
  display: inline-flex;
  gap: var(--spacing-050);
  align-items: center;

  color: var(--text-primary);
  text-decoration: none;

  &:hover {
    color: var(--text-link);
  }
}

.chevron {
  margin-bottom: -2px; /* stylelint-disable-line */
}

.tabs {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  padding: 0;
}

.all-button {
  flex-shrink: 0;
}

.title {
  margin: 0;
  font: var(--desktop-text-2xl-semibold);
}

.navigation {
  position: relative;

  display: flex;
  gap: var(--spacing-100);

  margin-top: var(--spacing-150);
  padding-bottom: var(--spacing-200);
}

.providers {
  margin-top: var(--spacing-300);
}

.events-count {
  color: var(--text-tertiary);
}
</style>
